header {
  background: #272029;
  color: #fff;
  min-height: 514px;

  box-shadow: 10px 8px 20px -5px rgba(0, 0, 0, 0.25);

  @include media-breakpoint-up(lg) {
    height: 55vh;
  }
}

main {
  .title {
    font-size: 1.15rem;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 0.75px;
  }

  .heading {
    h1 {
      font-size: 2.5rem;
      font-weight: 800;
    }
  }

  .contact {
    .item {
      margin: 0 10px;
    }

    a {
      color: #A395A4;
      text-decoration: none;
      @include transition(0.4s);

      &:hover {
        color: #EEE7F1;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .heading {
      h1 {
        font-size: 4.5rem;
      }
    }
  }
}